<script setup lang="ts">
import { useSiteStore } from '~/stores/site.store';
import useClock from '~/composables/useClock';
import { trackingMethods } from '~~/plugins/analytics.client';
import ThemedImage from '~/components/design-system/themed-image.vue';
import { EighteenPlus } from '~/components/design-system/icons';
import GenericLanguageSelector from '~/components/user-interface/generic-language-selector.vue';
import { placeholders } from 'assets/data/branding-library';

const { $enabled } = useNuxtApp();
const settings = useSiteStore();
const clock = useClock();
const config = useRuntimeConfig();

const { getDynamicImage, getPlaceholder, getImage } = useBranding();

const bankingImages = ['visa', 'master_card', 'zapper', 'ozow', 'apple_pay'];

const router = useRouter();
const navigate = (path) => {
  // send GA tracking method when Footer link clicked
  trackingMethods.footerClicked();
  router.push(path);
};

const displayApps = computed(
  () =>
    $enabled('website.enableappdownload') ||
    $enabled('website.enableandroidappdownload') ||
    $enabled('website.website.enablegoogleplayappdownload') ||
    $enabled('website.enableappleappdownload') ||
    $enabled('website.enablehuaweiappdownload'),
);

const displaySocials = computed(() => $enabled('website.enablesocialicons'));

const enableSocialAppSection = computed(
  () => displayApps.value || displaySocials.value,
);
</script>

<template>
  <div class="bg-layer-2 dark:bg-layer-1 pt-3 pb-20 md:py-12">
    <div class="container mx-auto">
      <div
        class="grid grid-cols-2 md:grid-cols-4 text-base-priority cursor-pointer text-sm md:text-base gap-4 mb-9"
      >
        <div
          v-for="item in settings.getNav({ nav: 'footer' })"
          :element-name="`page-link-${item.Alias}.toLowerCase()`"
          :key="item.Alias"
          class="footer-nav"
          @click="navigate(`${item.Path}`)"
        >
          {{ $t(item.Alias) }}
        </div>
      </div>
      <div
        v-if="settings.getRegionCode === 'JZA'"
        class="border-y-[1px] border-light-200 dark:border-dark-700 py-6 flex justify-center gap-4"
      >
        <ThemedImage
          v-for="(method, index) in bankingImages"
          :key="index"
          :alt-text="method"
          :light="
            getDynamicImage('bankingIcon', { provider: method, theme: 'dark' })
          "
          :dark="
            getDynamicImage('bankingIcon', { provider: method, theme: 'light' })
          "
          image-class="h-4 md:h-8"
        />
      </div>
      <div
        class="flex flex-nowrap w-full items-center border-y-[1px] border-light-200 dark:border-dark-700"
      >
        <GenericLanguageSelector />
        <div
          v-if="enableSocialAppSection"
          class="py-6 grow flex flex-wrap justify-center gap-2"
        >
          <UserInterfaceAppsDownload
            v-if="displayApps"
            type="footer"
            class="pt-0"
          />
          <UserInterfaceSocialIcons v-if="displaySocials" />
        </div>
      </div>
      <NuxtErrorBoundary>
        <div
          class="text-sm md:text-base dark:text-white text-center px-2 pt-2 pb-0"
        >
          <div v-html="$t(`license-info.${settings.getRegionCode}`)"></div>

          <div class="flex flex-col md:flex-row my-2 justify-between px-2">
            <div>
              {{ $t('software-version') }}
              <span class="font-bold">v{{ config.public.version }}</span>
            </div>
            &nbsp;
            <div>
              {{ $t('current-time') }}
              <span class="font-bold">{{ clock }}</span>
            </div>
          </div>
          <div class="flex-center">
            <a
              v-if="settings.getRegionCode === 'JZA'"
              :href="getImage('merLicense')"
              target="_blank"
            >
              <LazyDesignSystemLazyImage
                id="mer"
                class="w-20"
                :src="getImage('mpumalangaEconomicRegulator')"
                :placeholder="getPlaceholder('iconPlaceholder')"
                :style="`filter: invert(${
                  useColorMode().preference === 'dark' ? 1 : 0
                })`"
                alt="Mpumalanga Economic Regulator"
              />
            </a>
            <div class="bg-base dark:bg-layer-2 rounded-lg ml-2 p-2">
              <EighteenPlus />
            </div>
          </div>
        </div>
      </NuxtErrorBoundary>
    </div>
  </div>
</template>
<style scoped lang="scss">
.footer-nav {
  font-family: Inter;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  #wes-government {
    width: 50%;
  }

  #for-banking {
    display: grid !important;
    text-align: center;
  }
}

@media screen and (min-width: 1025px) {
  #wes-government {
    width: 30%;
  }
}
</style>
